import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {
  "title": "Getting started"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting started`}</h1>
    <p>{`While Blocks is in alpha, the recommended way to get started is to
use the demo.`}</p>
    <p><a parentName="p" {...{
        "href": "/demo/"
      }}>{`Try the demo `}{`→`}</a></p>
    <p>{`If you're a pioneering type and want to experiment with the editor in
other projects you can continue on:`}</p>
    <h2>{`Installation`}</h2>
    <p>{`Install the library and its peer dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`yarn add blocks-ui @mdx-js/react @blocks/react
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`npm i blocks-ui @mdx-js/react @blocks/react
`}</code></pre>
    <h2>{`Usage`}</h2>
    <p>{`You can use the block editor with the built-in blocks by only passing
a JSX source string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import Editor from 'blocks-ui'

const JSX = \`
import React from 'react'
import { HeaderBasic } from '@blocks/react'

export default () => (
  <Blocks.Root>
    <HeaderBasic>
      <HeaderBasic.Logo to="/">Hello</HeaderBasic.Logo>
      <HeaderBasic.Nav>
        <HeaderBasic.Link to="/about">About</HeaderBasic.Link>
        <HeaderBasic.Link to="/blog">Blog</HeaderBasic.Link>
        <HeaderBasic.Link to="/contact">Contact</HeaderBasic.Link>
      </HeaderBasic.Nav>
    </HeaderBasic>
  </Blocks.Root>
)
\`

export default () => <Editor src={JSX} />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      